import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import chandrakanth from '../../assets/hospital/chandrakanth nethralaya.png';
import chandrakanth_copy from '../../assets/hospital/chandrakanth nethralaya - Copy.png';
import ImageCard from '../../layouts/ImageCard';

const Hospital = () => {
  const [image, setImage] = useState(chandrakanth_copy);

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  useEffect(() => {
    const loadImage = (src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImage(src);
      };
    };

    loadImage(chandrakanth);
  }, []);

  const hospitals = [
    { name: "Dr. Chandrakanth Nethralaya", img: image, url: "https://cybozom.in/360/hospital/dr.chandrakanth/" },
  ];

  const navigateToExternalLink = (url) => {
    window.location.href = url;
  };

  return (
    <div className='mt-24'>
      <h1 className='text-[1.5rem] min-[425px]:text-[2rem] md:text-[3rem] tracking-widest text-[#293461] text-center'>HOSPITAL</h1>
      <hr className='w-full border border-[#293461]' />
      <div className='mt-10 grid grid-cols-1 min-[300px]:grid-cols-2 md:grid-cols-3 gap-5 sm:gap-10 mx-5 md:mx-10'>
        {hospitals.map((hospital, index) => (
          <button key={index} onClick={() => navigateToExternalLink(hospital.url)} className="focus:outline-none">
            <LazyLoad height={200} offset={100} once>
              <ImageCard name={hospital.name} img={hospital.img} />
            </LazyLoad>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Hospital;
