import React, { useEffect, useState } from 'react';
import markaz_intnl from '../../assets/school/markaz international.png';
import markaz_intnl_copy from '../../assets/school/markaz international - Copy.png';
import global from '../../assets/school/global english.png';
import global_copy from '../../assets/school/global english - Copy.png';
import gokulam from '../../assets/school/gokulam english.webp';
import gokulam_copy from '../../assets/school/gokulam english - Copy.png';
import markaz_eng from '../../assets/school/markaz english.png';
import markaz_eng_copy from '../../assets/school/markaz english - Copy.png';
import sadhbhavana from '../../assets/school/sadhbhavana world.png';
import sadhbhavana_copy from '../../assets/school/sadhbhavana world - Copy.png';
import excel_mahe from '../../assets/school/excel public.png';
import excel_mahe_copy from '../../assets/school/excel public - Copy.png';
import ImageCard from '../../layouts/ImageCard';

const School = () => {
  const [images, setImages] = useState({
    markazIntnlImg: markaz_intnl_copy,
    globalImg: global_copy,
    gokulamImg: gokulam_copy,
    markazEngImg: markaz_eng_copy,
    sadhbhavanaImg: sadhbhavana_copy,
    excelMaheImg: excel_mahe_copy,
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    const loadImage = (src, key) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImages(prevImages => ({ ...prevImages, [key]: src }));
      };
    };

    const imageSources = [
      { src: markaz_intnl, key: 'markazIntnlImg' },
      { src: global, key: 'globalImg' },
      { src: gokulam, key: 'gokulamImg' },
      { src: markaz_eng, key: 'markazEngImg' },
      { src: sadhbhavana, key: 'sadhbhavanaImg' },
      { src: excel_mahe, key: 'excelMaheImg' },
    ];

    imageSources.forEach(({ src, key }) => loadImage(src, key));
  }, []);

  const schools = [
    { name: "Sadhbhavana World School", img: images.sadhbhavanaImg, url: "https://www.sadhbhavanaschool.org/360/" },
    { name: "Excel Public School", img: images.excelMaheImg, url: "https://cybozom.site/360/Excel6/" },
    { name: "Global English School", img: images.globalImg, url: "https://cybozom.in/360/school/global/" },
    { name: "Markaz International School", img: images.markazIntnlImg, url: "https://cybozom.in/360/school/markaz/" },
    { name: "Gokulam English School - Calicut", img: images.gokulamImg, url: "https://cybozom.in/360/school/gokulamcalicut/" },
    { name: "Markaz English Medium School", img: images.markazEngImg, url: "https://cybozom.in/360/school/markazkaranthur/" },
  ];

  const navigateToExternalLink = (url) => {
    window.location.href = url;
  };

  return (
    <div className='mt-24'>
      <h1 className='text-[1.5rem] min-[425px]:text-[2rem] md:text-[3rem] tracking-widest text-[#293461] text-center'>SCHOOL</h1>
      <hr className='w-full border border-[#293461]' />
      <div className='mt-10 grid grid-cols-1 min-[300px]:grid-cols-2 md:grid-cols-3 gap-5 sm:gap-10 mx-5 md:mx-10'>
        {schools.map((school, index) => (
          <button key={index} onClick={() => navigateToExternalLink(school.url)} className="focus:outline-none">
            <ImageCard name={school.name} img={school.img} />
          </button>
        ))}
      </div>
    </div>
  );
};

export default School;
