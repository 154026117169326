import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import nilgiri from '../../assets/college/niligiri college.png';
import nilgiri_copy from '../../assets/college/niligiri college - Copy.png';
import jdt from '../../assets/college/jdt college.png';
import jdt_copy from '../../assets/college/jdt college - Copy.png';
import sree_gokulam from '../../assets/college/sree gokulam alappuzha.png';
import sree_gokulam_copy from '../../assets/college/sree gokulam alappuzha - Copy.png';
import ImageCard from '../../layouts/ImageCard';

const College = () => {
  const [images, setImages] = useState({
    sreeGokulamImg: sree_gokulam_copy,
    nilgiriImg: nilgiri_copy,
    jdtImg: jdt_copy,
  });

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  useEffect(() => {
    const loadImage = (src, key) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImages(prevImages => ({ ...prevImages, [key]: src }));
      };
    };

    const imageSources = [
      { src: sree_gokulam, key: 'sreeGokulamImg' },
      { src: nilgiri, key: 'nilgiriImg' },
      { src: jdt, key: 'jdtImg' },
    ];

    imageSources.forEach(({ src, key }) => loadImage(src, key));
  }, []);

  const colleges = [
    { name: "Sree Gokulam Cherthala", img: images.sreeGokulamImg, url: "https://cybozom.in/public/360/gokulamcherthala/" },
    { name: "Nilgiri College", img: images.nilgiriImg, url: "https://360.nilgiricollege.ac.in/" },
    { name: "JDT College", img: images.jdtImg, url: "https://cybozom.site/360/jdt/" },
  ];

  const navigateToExternalLink = (url) => {
    window.location.href = url;
  };

  return (
    <div className='mt-24'>
      <h1 className='text-[1.5rem] min-[425px]:text-[2rem] md:text-[3rem] tracking-widest text-[#293461] text-center'>COLLEGE</h1>
      <hr className='w-full border border-[#293461]' />
      <div className='mt-10 grid grid-cols-1 min-[300px]:grid-cols-2 md:grid-cols-3 gap-5 sm:gap-10 mx-5 md:mx-10'>
        {colleges.map((college, index) => (
          <button key={index} onClick={() => navigateToExternalLink(college.url)} className="focus:outline-none">
            <LazyLoad height={200} offset={100} once>
              <ImageCard name={college.name} img={college.img} />
            </LazyLoad>
          </button>
        ))}
      </div>
    </div>
  );
};

export default College;
